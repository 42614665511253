'use strict';

Gri.module({
  name: 'parallax-gamma',
  ieVersion: null,
  $el: $('.parallax-gamma'),
  container: '.parallax-gamma',
  fn: function () {
    
  }
});
